
import AutodeskConnectionButton from "@/components/connectors/AutodeskConnectionButton"

export default {
  components: { AutodeskConnectionButton },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    project: {
      type: Object,
      required: true,
    },
  },
  computed: {
    dialogIsOpen: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit("input", value)
      },
    },
    autodeskContext() {
      return {
        projectId: this.project.exid,
      }
    },
  },
}
