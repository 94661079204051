
import AutodeskSelection from "@/components/connectors/AutodeskSelection"

export default {
  components: {
    AutodeskSelection,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedAutodeskHub: null,
      selectedAutodeskProject: null,
      selectedAutodeskItem: null,
    }
  },
  computed: {
    dialogIsOpen: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit("input", value)
      },
    },
  },
}
